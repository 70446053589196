import React from 'react';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import {
  CheckoutFormProps,
  DefaultFormActions,
  Form,
  useEnhancedForm,
} from '../../apiForm/form';
import { TextField } from '../../apiForm/form/input';
import ArrayWrapper from '../../apiForm/wrapper/ArrayWrapper';
import ExpandableFieldset from '../../apiForm/form/ExpandableFieldset';

export default function OverrideTemplatesForm({
  defaultValues = {},
  errors = {},
  onSubmit,
  readonly,
}: CheckoutFormProps<any>) {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState,
    getValues,
    reset,
  } = useEnhancedForm({ defaultValues, errors });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <ExpandableFieldset
        legend={t('checkoutConfig.legend.overrideTemplates')}
        sublegend={t('checkoutConfig.sublegend.overrideTemplates')}
      >
        <ArrayWrapper
          control={control}
          name="overrideTemplates"
          defaultValue={{}}
          addLabel={t('checkoutConfig.arrayWrapper.overrideTemplate.add')}
          removeLabel={t('checkoutConfig.arrayWrapper.overrideTemplate.remove')}
          render={(index: number) => (
            <Stack spacing={2}>
              <TextField
                control={control}
                name={`overrideTemplates.[${index}].id`}
                disabled={readonly}
                label={t('checkoutConfig.label.overrideTemplate.id')}
              />
              <TextField
                control={control}
                name={`overrideTemplates.[${index}].template`}
                disabled={readonly}
                label={t('checkoutConfig.label.overrideTemplate.template')}
              />
              <TextField
                control={control}
                name={`overrideTemplates.[${index}].evaluationTemplate`}
                disabled={readonly}
                label={t('checkoutConfig.label.overrideTemplate.evaluationTemplate')}
                helperText={t('checkoutConfig.helperText.overrideTemplate.evaluationTemplate')}
              />
              <TextField
                control={control}
                name={`overrideTemplates.[${index}].transmissionCode`}
                disabled={readonly}
                label={t('checkoutConfig.label.overrideTemplate.transmissionCode')}
              />
              <TextField
                control={control}
                name={`overrideTemplates.[${index}].transmissionTemplate`}
                disabled={readonly}
                label={t('checkoutConfig.label.overrideTemplate.transmissionTemplate')}
              />
            </Stack>
          )}
        />
      </ExpandableFieldset>

      <DefaultFormActions
        formState={formState}
        cancelNavigationTarget="/"
        noCancelNavigation
        reset={reset}
        getValues={getValues}
      />
    </Form>
  );
}
